import Image from "next/image";
import { FC } from "react";
import Icon from "../Icon";
import Text from "../Text";
import styles from "./FeedbackItem.module.scss";
import Colors from "~/constants/colors";

const FeedbackItem: FC<{ feedback: any }> = ({ feedback }) => {
  return (
    <div className={styles.feedback}>
      <div className="flex items-center gap-3">
        <Image
          className={styles.avatar}
          src={feedback.avatar}
          alt={feedback.name}
          width={48}
          height={48}
        />

        <div className="flex flex-col flex-1 gap-1">
          <Text type="title-3" color={Colors.GREY_800}>
            {feedback.name}
          </Text>

          <div className="flex items-center gap-1">
            <Image
              src="/svgs/verify_fill.svg"
              alt="Verify"
              width={20}
              height={20}
            />
            <Text type="body-2" color={Colors.GREY_500}>
              Verified purchaser
            </Text>
          </div>
        </div>

        {feedback.star && (
          <div className="flex items-center gap-1">
            <Icon name="star-fill" color={Colors.ACCENT_YELLOW} />
            <Text type="title-3" color={Colors.GREY_800}>
              {feedback.star}
            </Text>
          </div>
        )}
      </div>

      <div className={styles.divider} />

      <Text type="title-2" color={Colors.GREY_600} className="!leading-normal">
        {feedback.title}
      </Text>

      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: feedback.description }}
      />
    </div>
  );
};

export default FeedbackItem;
