import { useTranslation } from "next-i18next";
import { FC } from "react";
import FeedbackItem from "~/components/FeedbackItem";
import InfiniteScroll from "~/components/InfiniteScroll";
import { customerFeedbacks } from "~/constants/data";
import Trans from "~/types/translationKeys";
import styles from "./CustomerFeedback.module.scss";

const CustomerFeedback: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.customer_feedback}>
      <div className={styles.container}>
        <h2 className={styles.title} data-aos="fade-up">
          {t(Trans.feedback_title)}
        </h2>

        <InfiniteScroll
          height={593}
          items={[
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[0]} />
              <FeedbackItem feedback={customerFeedbacks[7]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[3]} />
              <FeedbackItem feedback={customerFeedbacks[5]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[6]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[1]} />
              <FeedbackItem feedback={customerFeedbacks[8]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[4]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[9]} />
              <FeedbackItem feedback={customerFeedbacks[2]} />
            </div>,
            <div key="Feedback" className="flex flex-col gap-6">
              <FeedbackItem feedback={customerFeedbacks[10]} />
              <FeedbackItem feedback={customerFeedbacks[11]} />
            </div>,
          ]}
        />
      </div>
    </div>
  );
};

export default CustomerFeedback;
